import { configureStore } from "@reduxjs/toolkit";
import { throttle } from "lodash";
import { Middleware } from "redux";
import persistState from "redux-localstorage";
import createSagaMiddleware from "redux-saga";
import { ReduxAction } from "legacy/constants/ReduxActionConstants";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { router } from "router";
import { selectFlagById } from "store/slices/featureFlags/selectors";
import { isProduction } from "utils/env";
import { isChildRegistered, sendMessage } from "utils/iframe";
import logger from "utils/logger";
import { DEPLOYED_ACTIONS, EDIT_ACTIONS } from "./action-constants";
import { setSagaMiddleware } from "./dynamic";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { measureTiming, serializableCheck } from "./shared";
import { Flag } from "./slices/featureFlags";
import { reduxApiSlice } from "./slices/reduxApi";
import { isPayloadAction } from "./utils/action";
import type { AppState } from "store/types";

const sagaMiddleware = createSagaMiddleware({
  // since sagas are not in react's render tree, the only way to get context is to pass it in
  // available to all sagas using yield getContext(...)
  context: {
    navigate: router.navigate,
  },
  onError(error, errorInfo) {
    // This helps us collect any uncaught saga errors, we have a monitor on all warn-level logs
    logger.warn(`Saga error: ${error.message} at ${errorInfo.sagaStack}`);
  },
});

let actionQueue: ReduxAction<unknown>[] = [];
const MESSAGE_BATCH_TIME = 10;
const triggerSendFromQueue = throttle(() => {
  sendMessage(
    {
      type: "parent-action-batch",
      payload: actionQueue,
    },
    {
      overrideStartTime: Date.now() - MESSAGE_BATCH_TIME,
    },
  );
  actionQueue = [];
}, MESSAGE_BATCH_TIME);

const sendToIframe: Middleware = (store) => (next) => {
  return (action) => {
    if (!isPayloadAction(action)) return next(action);
    const iframeEnabled = selectFlagById(store.getState(), Flag.ENABLE_IFRAME);
    if (!iframeEnabled || !isChildRegistered()) return next(action);

    if (
      getAppMode(store.getState()) !== APP_MODE.EDIT &&
      !DEPLOYED_ACTIONS.includes(action.type)
    ) {
      return next(action);
    }

    if (!action.isChildAction && EDIT_ACTIONS.includes(action.type)) {
      actionQueue.push(action);
      triggerSendFromQueue();
    }

    return next(action);
  };
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck }).concat(
      sendToIframe,
      sagaMiddleware,
      reduxApiSlice.middleware,
      measureTiming,
    ),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(
      // TODO(Wylie): As part of the redux upgrade, the localstorage persistence needs to use this new API
      // https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
      persistState([], {
        slicer: (_paths: string[]) => (state: AppState) => {
          // state.legacy.ui.editorPreferences is what we want to persist
          return {
            editorPreferences: state.legacy.ui.editorPreferences.preferences,
            developerPreferences: state.legacy.ui.editorPreferences.developer,
          };
        },
      }),
    ),
  devTools:
    !isProduction() || window.location.search.includes("sb_redux")
      ? {
          name: "Superblocks main",
        }
      : false,
});

// Run your saga middleware
sagaMiddleware.run(rootSaga);
setSagaMiddleware(sagaMiddleware);

export default store;
