import { createAction } from "@reduxjs/toolkit";
import type { WidgetProps } from "legacy/widgets";

export const updateWidgetProperties = createAction(
  "UPDATE_WIDGET_PROPERTIES",
  (
    widgetId: string,
    updates: Record<string, unknown>,
    skipSave = false,
    clearReplayStack = false,
  ) => ({
    payload: {
      widgetId,
      updates,
      skipSave,
      clearReplayStack,
    },
  }),
);

export const updateWidgetAutoHeight = createAction(
  "WIDGET_UPDATE_AUTO_HEIGHT",
  (widgetId: string, heightInPixels: number) => ({
    payload: {
      widgetId,
      heightInPx: heightInPixels,
    },
  }),
);

export const updateWidgetAutoWidth = createAction(
  "WIDGET_UPDATE_AUTO_WIDTH",
  (widgetId: string, widthInPixels: number) => ({
    payload: {
      widgetId,
      widthInPx: widthInPixels,
    },
  }),
);

export const deleteWidgetProperty = createAction(
  "DELETE_WIDGET_PROPERTY",
  (widgetId: string, propertyPaths: string[]) => ({
    payload: {
      widgetId,
      propertyPaths,
    },
  }),
);

export const setSingleWidget = createAction(
  "SET_SINGLE_WIDGET",
  (
    widgetId: string,
    newWidget: WidgetProps,
    shouldReplay = true,
    savePage = false,
  ) => ({
    payload: {
      widgetId,
      newWidget,
      shouldReplay,
      savePage,
    },
  }),
);

export const setWidgetDynamicProperty = createAction(
  "SET_WIDGET_DYNAMIC_PROPERTY",
  (widgetId: string, propertyPath: string, isDynamic: boolean) => {
    return {
      payload: {
        widgetId,
        propertyPath,
        isDynamic,
      },
    };
  },
);

export const renameWidgets = createAction(
  "RENAME_WIDGETS",
  (
    updates: RenameWidgetsPayload["updates"],
    savePage = true,
    clearReplayStack = false,
  ) => ({
    payload: {
      updates,
      savePage,
      clearReplayStack,
    },
  }),
);

// Performs client side renaming without sending API updates
export const updateLocalWidgets = createAction(
  "UPDATE_LOCAL_WIDGETS",
  (updates: UpdateWidgetsPayload) => ({
    payload: {
      updates,
    },
  }),
);

export const renameEmbedProps = createAction(
  "RENAME_EMBED_PROPS",
  (updates: RenameEmbedPropsPayload["updates"]) => ({
    payload: {
      updates,
    },
  }),
);

export const setProfile = createAction("SET_PROFILE", (profileId: string) => ({
  payload: {
    profileId,
  },
}));

export interface UpdateWidgetPropertiesPayload {
  widgetId: string;
  skipSave?: boolean;
  updates: Record<string, unknown>;
  clearReplayStack?: boolean;
}

export interface SetWidgetDynamicPropertyPayload {
  widgetId: string;
  propertyPath: string;
  isDynamic: boolean;
}

export interface DeleteWidgetPropertyPayload {
  widgetId: string;
  propertyPaths: string[];
}

export interface UpdateCanvasHeightPayload {
  canvasWidgetId: string;
  gridRows: number;
}

interface RenameWidgetsPayload {
  updates: Array<{
    widgetId: string;
    propertyName: string;
    propertyValue: unknown;
  }>;
  skipSave?: boolean;
  clearReplayStack?: boolean;
}

export interface RenameEmbedPropUpdate {
  embedPropId: string;
  propertyName: string;
  propertyValue: unknown;
}

interface RenameEmbedPropsPayload {
  updates: Array<RenameEmbedPropUpdate>;
}

type UpdateWidgetsPayload = Array<{
  widgetId: string;
  propertyName: string;
  propertyValue: unknown;
}>;
