import generate from "nanoid/generate";
import { MODAL_TARGET_ID } from "legacy/constants/WidgetConstants";

const ALPHANUMERIC = "1234567890abcdefghijklmnopqrstuvwxyz";
// const ALPHABET = "abcdefghijklmnopqrstuvwxyz";

export const generateReactKey = ({
  prefix = "",
}: { prefix?: string } = {}): string => {
  return prefix + generate(ALPHANUMERIC, 10);
};

// Before you change how this works
// This className is used for the following:
// 1. Resize bounds
// 2. Property pane reference for positioning
// superblocks_widget_0 is also used for setting background color of canvas root
export const generateClassName = (seed?: string) => {
  return `superblocks_widget_${seed}`;
};

export const generateDropTargetElementId = (parentWidgetId: string) =>
  `drop-target-${parentWidgetId}`;

export const generateSectionComponentId = (sectionWidgetId: string) =>
  `section-widget-${sectionWidgetId}`;

export const generateCanvasSelectionComponentId = (canvasWidgetId: string) =>
  `canvas-selection-${canvasWidgetId}`;

export const getCanvasClassName = () => "canvas";

export const getNearestScrollableCanvas = (el?: Element | null) => {
  if (el) {
    return el.closest(
      `[data-superblocks=${MODAL_TARGET_ID}], [data-superblocks^=slideout-scroll-area-]`,
    ) as HTMLElement;
  } else {
    return document.querySelector(
      `[data-superblocks=${MODAL_TARGET_ID}]`,
    ) as HTMLElement;
  }
};
