import {
  AccessMode,
  IntegrationKind,
  OAUTH_CALLBACK_PATH,
} from "@superblocksteam/shared";
import React, { memo, Suspense } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useRouteError,
  createMemoryRouter,
} from "react-router-dom";
import Auth0ProviderWithHistory from "auth/authProvider";
import AuthWrapper from "auth/authWrapper";
import { LoginComponent, LogoutComponent, SignupComponent } from "auth/utils";
import EmailVerificationWrapper from "components/app/EmailVerification/EmailVerificationWrapper";
import { IntercomOverlay } from "components/app/Intercom/IntercomOverlay";
import SurveyWrapper from "components/app/Survey/SurveyWrapper";
import { FullPageSpinner } from "components/ui/FullPageSpinner";
import {
  VIEW_ACCESS_TOKENS,
  VIEW_AGENTS,
  VIEW_GROUPS,
  VIEW_OBSERVABILITY,
  VIEW_PROFILES,
  VIEW_REPOSITORIES,
  VIEW_ROLES,
  VIEW_SECRET_STORES,
  VIEW_USERS,
  VIEW_GROUP_MEMBERS,
} from "constants/rbac";
import { useFeatureFlag } from "hooks/ui";
import { JwtContextProvider } from "hooks/ui/JwtProvider";
import {
  ApplicationRoute,
  EditorRoute,
  HOME_URL,
} from "legacy/constants/routes";
import AppViewerLoader from "legacy/pages/AppViewer/loader";
import ErrorPage from "legacy/pages/common/ErrorPage";
import PageLoadingBar from "legacy/pages/common/PageLoadingSpinner";
import PageNotFound from "legacy/pages/common/PageNotFound";
import {
  getSafeCrash,
  getSafeCrashCode,
  getSafeCrashReason,
} from "legacy/selectors/errorSelectors";
import { getAccessMode } from "legacy/selectors/usersSelectors";
import AccessTokensPage from "pages/AccessTokens";
import { AuthorizedPageWrapper } from "pages/AccessTokens/AuthorizedPageWrapper";
import Agents from "pages/Agents";
import AgentDeploymentInstructionPage from "pages/Agents/AgentDeploymentInstructionPage";
import AuditLogs from "pages/AuditLogs";
import CheckoutLoader from "pages/Checkout/loader";
import CheckoutCompletePage from "pages/CheckoutComplete";
import EditorLoader from "pages/Editors/loader";
import {
  CatchRedirectEditor,
  WidgetsDeployedRedirect,
  WidgetsEditorRedirect,
  WidgetsPreviewRedirect,
} from "pages/Editors/redirects";
import Home from "pages/Home";
import DowngradeModal from "pages/Home/DowngradeModal";
import PricingModal from "pages/Home/PricingModal";
import PurchasedModal from "pages/Home/PurchasedModal";
import QualificationModal from "pages/Home/QualificationModal";
import Integrations from "pages/Integrations";
import { IntegrationForm } from "pages/Integrations/IntegrationForm";
import Permissions from "pages/Permissions/Permissions";
import RolesAndPermissions from "pages/Permissions/RolesAndPermissions";
import PersonalSettings from "pages/PersonalSettings";
import Profiles from "pages/Profiles";
import Repositories from "pages/Repositories";
import RepositoryDetails from "pages/Repositories/RepositoryDetails";
import SecretsManagement from "pages/SecretsManagement";
import UsersPage from "pages/Users";
import GitHubOAuthCallback from "pages/components/GithubOAuthCallback";
import OAuthImplicitCallback from "pages/components/OAuthCallback";
import { ROLES_AND_PERMISSIONS_URL } from "pages/routes";
import { Flag } from "store/slices/featureFlags";
import { useTracingManager } from "tracing/hooks";
import { EmbedWrapper } from "utils/embed/EmbedWrapper";
import { isDev } from "utils/env";
import { ApiProfileReloader } from "./ApiProfileReloader";
import Groups from "./pages/Groups";
import GroupDetail from "./pages/Groups/GroupDetail";
import Observability from "./pages/Observability";

const loadingIndicator = <PageLoadingBar />;

function PrivateRoutes() {
  return (
    <AuthWrapper>
      <JwtContextProvider>
        <EmailVerificationWrapper>
          <SurveyWrapper>
            <Outlet />
          </SurveyWrapper>
        </EmailVerificationWrapper>
      </JwtContextProvider>
    </AuthWrapper>
  );
}

// NOTE: These are ONLY used in local development. In all other modes, the embed pages
// are served from index-embed / embedRouter
function EmbeddedPrivateRoutes() {
  return (
    <EmbedWrapper>
      <AuthWrapper>
        <JwtContextProvider>
          <EmailVerificationWrapper>
            <SurveyWrapper>
              <Outlet />
            </SurveyWrapper>
          </EmailVerificationWrapper>
        </JwtContextProvider>
      </AuthWrapper>
    </EmbedWrapper>
  );
}

const MemoizedEmbeddedPrivateRoutes = memo(EmbeddedPrivateRoutes);

const MemoizedPrivateRoutes = memo(PrivateRoutes);

async function lazyWidgetEditor() {
  const { default: WidgetsEditor } = await import(
    /* webpackChunkName: "editor" */ "legacy/pages/Editor/WidgetsEditor"
  );
  return { element: <WidgetsEditor /> };
}

function FeatureFlagGate(props: { flag: Flag; children: JSX.Element }) {
  const isFeatureFlagEnabled = useFeatureFlag(props.flag);
  if (!isFeatureFlagEnabled) {
    return <Navigate to={HOME_URL} />;
  }
  return props.children ?? null;
}

function AccessModeRedirect() {
  const accessMode = useSelector(getAccessMode);
  if (accessMode === AccessMode.VISITOR) {
    return <Navigate to={"/login"} />;
  }
  return <Navigate to={"/home"} />;
}

function TopLevelRoutes() {
  useTracingManager();

  const safeCrash = useSelector(getSafeCrash);
  const safeCrashCode = useSelector(getSafeCrashCode);
  const safeCrashReason = useSelector(getSafeCrashReason);

  if (safeCrash && safeCrashCode) {
    return (
      <Auth0ProviderWithHistory>
        <ErrorPage code={safeCrashCode} reason={safeCrashReason} />
      </Auth0ProviderWithHistory>
    );
  }

  return (
    <Auth0ProviderWithHistory>
      <Outlet />

      {/* These can appear on any page */}
      <DowngradeModal />
      <PricingModal />
      <PurchasedModal />
      <QualificationModal />

      {/* TODO: Remove this once control flow is fully released */}
      <ApiProfileReloader />
    </Auth0ProviderWithHistory>
  );
}

// See https://github.com/remix-run/react-router/discussions/10166
const BubbleError = () => {
  const error = useRouteError();
  throw error;
};

// detect if running in test environment because router depends on some browser apis
export const router = (
  typeof jest !== "undefined" ? createMemoryRouter : createBrowserRouter
)([
  {
    element: <TopLevelRoutes />,
    errorElement: <BubbleError />,
    children: [
      { path: "/login", element: <LoginComponent /> },
      { path: "/signup", element: <SignupComponent /> },
      { path: "/logout", element: <LogoutComponent /> },
      ...(isDev()
        ? [
            {
              path: "/embed/applications/preview/*",
              element: <MemoizedEmbeddedPrivateRoutes />,
              children: [
                {
                  path: ApplicationRoute.Viewer,
                  // will redirect itself on first load
                  element: (
                    <AppViewerLoader embedMode={true} previewMode={true} />
                  ),
                },
              ],
            },
            {
              path: "/embed/applications/*",
              element: <MemoizedEmbeddedPrivateRoutes />,
              children: [
                {
                  path: ApplicationRoute.Viewer,
                  // will redirect itself on first load
                  element: <AppViewerLoader embedMode={true} />,
                },
              ],
            },
          ]
        : []),
      {
        element: <MemoizedPrivateRoutes />,
        children: [
          {
            path: "integrations",
            element: (
              <IntercomOverlay>
                <Integrations />
              </IntercomOverlay>
            ),
          },
          {
            path: "profiles",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_PROFILES}>
                  <Profiles />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "repositories",
            element: (
              <FeatureFlagGate flag={Flag.ENABLE_REPOSITORIES_PAGE}>
                <IntercomOverlay>
                  <AuthorizedPageWrapper permission={VIEW_REPOSITORIES}>
                    <Repositories />
                  </AuthorizedPageWrapper>
                </IntercomOverlay>
              </FeatureFlagGate>
            ),
          },
          {
            path: "repositories/:repositoryId",
            element: (
              <FeatureFlagGate flag={Flag.ENABLE_REPOSITORIES_PAGE}>
                <IntercomOverlay>
                  <RepositoryDetails />
                </IntercomOverlay>
              </FeatureFlagGate>
            ),
          },
          {
            path: ApplicationRoute.Base,
            children: [
              {
                path: ApplicationRoute.Preview,
                element: <AppViewerLoader previewMode={true} />,
              },
              {
                // Deprecated route
                path: ApplicationRoute.DeprecatedPreview,
                element: <WidgetsPreviewRedirect />,
              },
              {
                // Deprecated route
                path: ApplicationRoute.DeprecatedPageEdit,
                element: <CatchRedirectEditor />,
              },
              {
                path: EditorRoute.EditApplication,
                // Loads the editor layout, contents are filled based on route
                element: <EditorLoader />,
                children: [
                  {
                    path: EditorRoute.DeprecatedEditApiAction,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.DeprecatedEditApiInputs,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.DeprecatedEditApiResponse,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.DeprecatedEditApi,
                    element: <WidgetsEditorRedirect />,
                  },
                  {
                    path: EditorRoute.EditApi,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiActionBase,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiAction,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiInputs,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditApiResponse,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: EditorRoute.EditEntityProperty,
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: "",
                    lazy: lazyWidgetEditor,
                  },
                  {
                    path: "*",
                    lazy: lazyWidgetEditor,
                  },
                ],
              },
              {
                path: ApplicationRoute.DeprecatedViewer,
                element: <WidgetsDeployedRedirect />,
              },
              {
                path: ApplicationRoute.Viewer,
                element: <AppViewerLoader />,
              },
              {
                path: "*",
                element: <AccessModeRedirect />,
              },
            ],
          },
          {
            path: "home?/*",
            element: (
              <IntercomOverlay>
                <Home />
              </IntercomOverlay>
            ),
          },
          {
            path: "opas",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_AGENTS}>
                  <Agents />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "opas/instructions",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_AGENTS}>
                  <AgentDeploymentInstructionPage />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "access-tokens",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_ACCESS_TOKENS}>
                  <AccessTokensPage />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "users",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_USERS}>
                  <UsersPage />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "groups",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_GROUPS}>
                  <Groups />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "groups/:groupId/:pageType",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_GROUP_MEMBERS}>
                  <GroupDetail />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "permissions/:pageType",
            element: (
              <IntercomOverlay>
                <Permissions />
              </IntercomOverlay>
            ),
          },
          {
            path: ROLES_AND_PERMISSIONS_URL(),
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_ROLES}>
                  <RolesAndPermissions />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "audit",
            element: (
              <IntercomOverlay>
                <AuditLogs />
              </IntercomOverlay>
            ),
          },
          {
            path: "integrations/:pluginId/:datasourceId",
            element: (
              <IntercomOverlay>
                <IntegrationForm kind={IntegrationKind.PLUGIN} />
              </IntercomOverlay>
            ),
          },
          {
            path: "integrations/:pluginId",
            element: (
              <IntercomOverlay>
                <IntegrationForm kind={IntegrationKind.PLUGIN} />
              </IntercomOverlay>
            ),
          },
          {
            path: "workflows/:apiId/action?/:actionId?/*",
            async lazy() {
              const { WorkflowEditor } = await import(
                /* webpackChunkName: "WorkflowEditor" */
                "./pages/Editors/ApiEditor/WorkflowEditor"
              );
              return {
                element: <WorkflowEditor key={1} />,
              };
            },
          },
          {
            path: "workflows",
            element: <AccessModeRedirect />,
          },
          {
            path: "scheduled_jobs/:apiId/action?/:actionId?/*",
            async lazy() {
              const { WorkflowEditor } = await import(
                /* webpackChunkName: "WorkflowEditor" */
                "./pages/Editors/ApiEditor/WorkflowEditor"
              );
              return {
                element: <WorkflowEditor key={1} />,
              };
            },
          },
          {
            path: "scheduled_jobs",
            element: <AccessModeRedirect />,
          },
          {
            path: "oauth/github-callback",
            element: <GitHubOAuthCallback />,
          },
          {
            path: "observability/:pageType?",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_OBSERVABILITY}>
                  <Observability />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "secrets-management",
            element: (
              <IntercomOverlay>
                <AuthorizedPageWrapper permission={VIEW_SECRET_STORES}>
                  <SecretsManagement />
                </AuthorizedPageWrapper>
              </IntercomOverlay>
            ),
          },
          {
            path: "secrets-management/:pluginId/:datasourceId",
            element: (
              <IntercomOverlay>
                <IntegrationForm kind={IntegrationKind.SECRET} />
              </IntercomOverlay>
            ),
          },
          {
            path: "secrets-management/:pluginId",
            element: (
              <IntercomOverlay>
                <IntegrationForm kind={IntegrationKind.SECRET} />
              </IntercomOverlay>
            ),
          },
          {
            path: "personal-settings",
            element: (
              <IntercomOverlay>
                <PersonalSettings />
              </IntercomOverlay>
            ),
          },
          {
            path: "checkout",
            element: (
              <IntercomOverlay>
                <CheckoutLoader />
              </IntercomOverlay>
            ),
          },
          {
            path: "checkout/complete",
            element: (
              <IntercomOverlay>
                <CheckoutCompletePage />
              </IntercomOverlay>
            ),
          },
          {
            path: OAUTH_CALLBACK_PATH,
            element: <OAuthImplicitCallback />,
          },
        ],
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
]);

const Router = () => {
  return (
    <Suspense fallback={loadingIndicator}>
      <RouterProvider router={router} fallbackElement={<FullPageSpinner />} />
    </Suspense>
  );
};

// Uses basic memoization because routes can't change
export default memo(Router);
